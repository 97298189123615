var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "fulfillment-question-main" }, [
    _c("h1", { staticClass: "fulfillment-title" }, [_vm._v(_vm._s(_vm.title))]),
    _c("p", { staticClass: "fulfillment-paragrapf" }, [
      _vm._v(_vm._s(_vm.body)),
    ]),
    _c(
      "div",
      { staticClass: "fulfillment-img-container" },
      [_vm._t("image")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }