import { render, staticRenderFns } from "./PreviredRemoteAssisted.vue?vue&type=template&id=56d4f40e&scoped=true"
import script from "./PreviredRemoteAssisted.vue?vue&type=script&lang=js"
export * from "./PreviredRemoteAssisted.vue?vue&type=script&lang=js"
var disposed = false

function injectStyles (context) {
  if (disposed) return
  var style0 = require("./PreviredRemoteAssisted.vue?vue&type=style&index=0&id=56d4f40e&scoped=true&lang=css")
if (style0.__inject__) style0.__inject__(context)

}


  module.hot && module.hot.dispose(function (data) {
    disposed = true
  })

/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "56d4f40e",
  null
  ,true
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/fulfillment-previred-widget/fulfillment-previred-widget/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('56d4f40e')) {
      api.createRecord('56d4f40e', component.options)
    } else {
      api.reload('56d4f40e', component.options)
    }
    module.hot.accept("./PreviredRemoteAssisted.vue?vue&type=template&id=56d4f40e&scoped=true", function () {
      api.rerender('56d4f40e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/PreviredRemoteAssisted.vue"
export default component.exports