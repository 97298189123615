var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "previred-remote-asisted" },
    [
      _c(
        "ProgressMark",
        {
          attrs: {
            circle: "",
            "circle-line-top": "",
            status: _vm.getFirstPMStatus(),
          },
        },
        [
          _c("div", { staticClass: "sw-step" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.isExecutiveValidated()
                    ? "Ejecutivo validado exitosamente"
                    : ""
                ) +
                " "
            ),
          ]),
        ]
      ),
      _c(
        "ProgressMark",
        { attrs: { "circle-line-bottom": "", status: _vm.getFirstPMStatus() } },
        [
          _vm.showAuthWidget && !_vm.hasErrors
            ? _c(
                "div",
                { staticClass: "auth" },
                [
                  _vm.hasErrors
                    ? _c("div", [_vm._v(_vm._s(_vm.errorMessage))])
                    : _vm._e(),
                  _vm.isInit()
                    ? _c("FulfillmentAuthentication", {
                        attrs: {
                          rut: _vm.executiveRut,
                          "oti-mode": true,
                          method: "fingerprint",
                          "button-enabled": true,
                          "button-label": "Validar ejecutivo",
                          "dummy-mode": _vm.dummyMode,
                          "trace-id": _vm.traceId,
                          "kong-token": _vm.kongToken,
                          "kong-client-id": _vm.kongClientId,
                          "kong-client-secret": _vm.kongClientSecret,
                          environment: _vm.environment,
                          "channel-key": _vm.channelKey,
                        },
                        on: { auth: _vm.onExecutiveAuthentication },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          !_vm.showAuthWidget && !_vm.advisoryCode && !_vm.hasErrors
            ? _c("Spinner")
            : _vm._e(),
          !_vm.showAuthWidget && _vm.advisoryCode && !_vm.hasErrors
            ? _c("div", [
                _c("p", { staticClass: "advisory-code-message" }, [
                  _vm._v(
                    " Para continuar, indícale al cliente que ingrese el siguiente código en el sitio web de Cuprum: "
                  ),
                ]),
                _c("p", { staticClass: "advisory-code" }, [
                  _c("strong", [_vm._v(_vm._s(_vm.advisoryCode))]),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "ProgressMark",
        {
          attrs: {
            circle: "",
            "circle-line-top": "",
            status: _vm.getLastPMStatus(),
          },
        },
        [
          _c("div", { staticClass: "sw-step" }, [
            _vm.isExecutiveValidated()
              ? _c("div", { staticClass: "remote-assisted" }, [
                  _vm.isPollingOn && !_vm.hasErrors
                    ? _c("div", [
                        _c("span", [_vm._v(_vm._s(_vm.pollingTitle))]),
                      ])
                    : _vm._e(),
                  _vm.hasErrors
                    ? _c("div", [
                        _vm._v(
                          _vm._s(_vm.errorTitle) +
                            ": Por seguridad no es posible continuar."
                        ),
                      ])
                    : _vm._e(),
                  _vm.isClientValidated()
                    ? _c("div", [_vm._v("Cliente válidado con éxito.")])
                    : _vm._e(),
                ])
              : _vm._e(),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }