var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "previred-equifax" }, [
    _c("div", { staticClass: "sw-step" }, [
      _vm.showAuthWidget
        ? _c(
            "div",
            { staticClass: "auth" },
            [
              _c("FulfillmentAuthentication", {
                attrs: {
                  rut: _vm.rut,
                  company: "cuprum",
                  "oti-mode": false,
                  method: "questions",
                  provider: _vm.provider,
                  "time-limit-mode": _vm.timeLimitMode,
                  color: _vm.color,
                  "button-enabled": true,
                  "button-label": "Validar ejecutivo",
                  "dummy-mode": _vm.dummyMode,
                  "trace-id": _vm.traceId,
                  "kong-token": _vm.kongToken,
                  "kong-client-id": _vm.kongClientId,
                  "kong-client-secret": _vm.kongClientSecret,
                  environment: _vm.environment,
                  "channel-key": _vm.channelKey,
                },
                on: {
                  auth: _vm.onAuthentication,
                  goBack: function ($event) {
                    return _vm.$emit("goBack")
                  },
                },
              }),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "previred" },
            [
              _vm.isPollingOn && !_vm.hasErrors
                ? _c(
                    "Result",
                    {
                      attrs: {
                        title: "Tu identidad ha sido validada",
                        body: "Por favor espera, estamos obteniendo tus datos de afiliado",
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "hourglass",
                        attrs: {
                          slot: "image",
                          src: "https://static.dx.principal.cl/fulfillment/assets/previred/hourglass.png",
                          alt: "waiting",
                        },
                        slot: "image",
                      }),
                    ]
                  )
                : _vm._e(),
              !_vm.isPollingOn && !_vm.hasErrors
                ? _c(
                    "Result",
                    {
                      attrs: {
                        title: "Tus datos han sido obtenidos",
                        body: "Ya puedes migrar a CuprumAFP",
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          slot: "image",
                          src: "https://static.dx.principal.cl/fulfillment/assets/auth/kba/success_image.svg",
                          alt: "success_image",
                        },
                        slot: "image",
                      }),
                    ]
                  )
                : _vm._e(),
              _vm.hasPreviredError
                ? _c(
                    "Result",
                    {
                      attrs: {
                        title: "No es posible verificar tu información",
                        body: "Por la seguridad de tu información no es posible continuar.",
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          slot: "image",
                          src: "https://static.dx.principal.cl/fulfillment/assets/auth/kba/error_image.svg",
                          alt: "error_image",
                        },
                        slot: "image",
                      }),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }