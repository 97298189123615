var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "previred-acepta" },
    [
      _c(
        "ProgressMark",
        {
          attrs: {
            circle: "",
            "circle-line-top": "",
            status: _vm.getFirstPMStatus(),
          },
        },
        [
          _c("div", { staticClass: "sw-step" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.isExecutiveValidated()
                    ? "Ejecutivo validado exitosamente"
                    : ""
                ) +
                " "
            ),
            _c(
              "div",
              { staticClass: "auth" },
              [
                _vm.isInit()
                  ? _c("FulfillmentAuthentication", {
                      attrs: {
                        rut: _vm.executiveRut,
                        "oti-mode": true,
                        method: "fingerprint",
                        "button-enabled": true,
                        "button-label": "Validar ejecutivo",
                        "dummy-mode": _vm.dummyMode,
                        "trace-id": _vm.traceId,
                        "kong-token": _vm.kongToken,
                        "kong-client-id": _vm.kongClientId,
                        "kong-client-secret": _vm.kongClientSecret,
                        environment: _vm.environment,
                        "channel-key": _vm.channelKey,
                      },
                      on: {
                        click: _vm.clearErrors,
                        auth: _vm.executiveValidation,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]
      ),
      _c("ProgressMark", {
        attrs: { "circle-line-bottom": "", status: _vm.getFirstPMStatus() },
      }),
      _c(
        "ProgressMark",
        {
          attrs: {
            circle: "",
            "circle-line-top": "",
            status: _vm.getLastPMStatus(),
          },
        },
        [
          _c("div", { staticClass: "sw-step" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.isClientValidated() ? "Cliente validado exitosamente" : ""
                ) +
                " "
            ),
            _c(
              "div",
              { staticClass: "auth" },
              [
                _vm.isPollingOn
                  ? _c("Spinner", {
                      attrs: { message: "Solicitando información a PREVIRED" },
                    })
                  : _vm._e(),
                _vm.isExecutiveValidated() &&
                !_vm.isClientValidated() &&
                !_vm.isPollingOn
                  ? _c("FulfillmentAuthentication", {
                      attrs: {
                        rut: _vm.rut,
                        "oti-mode": true,
                        method: "fingerprint",
                        "button-enabled": true,
                        "button-label": "Validar cliente",
                        "dummy-mode": _vm.dummyMode,
                        "trace-id": _vm.traceId,
                        "kong-token": _vm.kongToken,
                        "kong-client-id": _vm.kongClientId,
                        "kong-client-secret": _vm.kongClientSecret,
                        environment: _vm.environment,
                        "channel-key": _vm.channelKey,
                      },
                      on: {
                        click: _vm.clearErrors,
                        auth: _vm.clientValidation,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm.hasErrors
        ? _c("div", { staticClass: "errors" }, [
            _vm._v(_vm._s(_vm.errorMessage)),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }